'use client'
import { TemplateUserConfigResp } from '@/lib/types'
import { BlockMap } from 'notion-types'
import React from 'react'

const ServerContext = React.createContext<{
  blockMap: BlockMap
  config: TemplateUserConfigResp
  collectionViewMap: any
}>({ blockMap: {}, config: {} as any, collectionViewMap: {} })

export default ServerContext
