/* eslint-disable @next/next/no-img-element */
import ActiveLink from '@/components/home/activeLink'
import RenderChildText from '@/components/notion-related/renderChildText'
import { useIsSubscribed, useUserConfig } from '@/lib/hooks/context'
import { isInSiteLink } from '@/lib/utils/isInSiteLink'
import { RssIcon } from '@heroicons/react/24/outline'
import Link from 'next/link'
import { Decoration } from 'notion-types'

interface HeaderProps {
  avatar: string
  titleProperties: Decoration[]
  isFromNavigation: boolean
}

const Header = ({ avatar, titleProperties, isFromNavigation }: HeaderProps) => {
  const config = useUserConfig()
  const isSubscribed = useIsSubscribed()

  return (
    <div>
      <div className="mb-16 flex space-x-4 text-orange-400">
        {config.navigation.map((item, index) => {
          const href =
            item.type === 'home' ? '/' : `/${item.label}`.toLowerCase()
          return (
            <div key={index} className="cursor-pointer">
              {isInSiteLink(item.type) ? (
                <ActiveLink href={href} passHref activeClassName="font-bold">
                  <a>{item.label}</a>
                </ActiveLink>
              ) : (
                <a
                  target="_blank"
                  href={
                    item.value?.startsWith('http')
                      ? item.value
                      : 'https://' + item.value
                  }
                  rel="noopener noreferrer"
                >
                  {item.label}
                </a>
              )}
            </div>
          )
        })}
      </div>
      {!isFromNavigation && (
        <div className="mb-8 flex flex-col items-start space-y-4 sm:flex-row sm:items-center sm:justify-between sm:space-y-0">
          <Link href="/" passHref>
            <div className="flex flex-row items-center text-orange-600">
              <img
                className="mr-3 h-16 w-16 rounded-full object-cover"
                src={avatar}
                alt="avatar"
              />
              <RenderChildText properties={titleProperties} />
            </div>
          </Link>

          {isSubscribed && (
            <Link href="/rss.xml" passHref>
              <RssIcon className="h-5 w-5 cursor-pointer text-orange-600" />
            </Link>
          )}
        </div>
      )}
    </div>
  )
}

export default Header
