'use client'
import useSiteUserAvatar from '@/lib/hooks/useSiteUserAvatar'
import { SiteInfo, TemplateUserConfigResp } from '@/lib/types'
import formatNotionDate from '@/lib/utils/formatNotionDate'
import getBlogPath from '@/lib/utils/getBlogPath'
import Link from 'next/link'
import { getTextContent } from 'notion-utils'
import React from 'react'
import Header from './components/header'
import Layout from './components/layout'

const SimpleBlogIndexPage: React.FC<{
  resp: SiteInfo
  config: TemplateUserConfigResp
}> = ({ resp }) => {
  const avatar = useSiteUserAvatar()
  return (
    <Layout
      header={
        <Header
          avatar={avatar}
          titleProperties={resp.title}
          isFromNavigation={false}
        />
      }
      content={
        <section className="flex-auto pt-8">
          {resp.items.map((article, idx) => (
            <div
              key={idx}
              className="mb-8 flex flex-col-reverse items-start sm:flex-row sm:items-center"
            >
              <div className="mr-3 mt-1 font-mono text-xs text-gray-400 sm:mt-0">
                {formatNotionDate(article.properties.date, 'MMM DD, YYYY') ||
                  'Date Unknown'}
              </div>
              <Link
                href={getBlogPath(article.properties.slug)}
                as={getBlogPath(article.properties.slug)}
                passHref
                className="text-orange-600"
              >
                {getTextContent(article.properties.page)}
              </Link>
            </div>
          ))}
        </section>
      }
    />
  )
}

export default SimpleBlogIndexPage
