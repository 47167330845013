'use client'
/* eslint-disable @next/next/no-img-element */
import RenderChildText from '@/components/notion-related/renderChildText'
import NotionContent from '@/components/notionContent'
import useSiteOwnerName from '@/lib/hooks/useSiteOwnerName'
import useSiteUserAvatar from '@/lib/hooks/useSiteUserAvatar'
import { BlogDetailPageProps } from '@/lib/types'
import formatNotionDate from '@/lib/utils/formatNotionDate'
import SharedFooter from '../sharedFooter'

const WindBlogDetailPage = ({ recordMap, blogInfo }: BlogDetailPageProps) => {
  const userName = useSiteOwnerName()
  const avatar = useSiteUserAvatar()

  return (
    <div className="relative bg-white overflow-x-hidden pt-16">
      <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full">
        <div
          className="relative mx-auto h-full max-w-prose text-lg"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="mx-auto mt-6 max-w-3xl text-gray-500">
          <div className="mb-8 flex items-center">
            <div className="flex-shrink-0">
              <a href="/">
                <span className="sr-only">{userName}</span>
                <img
                  className="h-14 w-14 rounded-full object-cover"
                  src={avatar}
                  alt="avatar"
                />
              </a>
            </div>
            <div className="ml-3">
              <p className="text-base font-medium text-gray-900">{userName}</p>
              <div className="flex space-x-1 text-sm text-gray-500">
                {formatNotionDate(blogInfo.date, 'MMM DD, YYYY')}
              </div>
            </div>
          </div>

          <h1>
            <span className="mt-2 mb-8 block text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              <RenderChildText properties={blogInfo.page as any} />
            </span>
          </h1>

          <NotionContent recordMap={recordMap} />

          <SharedFooter />
        </div>
      </div>
    </div>
  )
}

export default WindBlogDetailPage
