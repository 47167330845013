'use client'
/* eslint-disable @next/next/no-img-element */
import { SharedNavigation } from '@/components/blogTemplates/sharedNavigation'
import TimelineBlogFooter from '@/components/blogTemplates/timeline/timelineBlogFooter'
import RenderChildText from '@/components/notion-related/renderChildText'
import NotionContent from '@/components/notionContent'
import { useUserConfig } from '@/lib/hooks/context'
import useSiteOwnerName from '@/lib/hooks/useSiteOwnerName'
import useSiteUserAvatar from '@/lib/hooks/useSiteUserAvatar'
import { NotionCollectionDate } from '@/lib/types'
import formatNotionDate from '@/lib/utils/formatNotionDate'
import { Decoration, ExtendedRecordMap } from 'notion-types'

const TimelineLayout = ({
  date,
  titleProperties,
  contentRecordMap,
}: {
  titleProperties: Decoration[]
  date: NotionCollectionDate | undefined
  contentRecordMap: ExtendedRecordMap
}) => {
  const config = useUserConfig()
  const userName = useSiteOwnerName()
  const avatar = useSiteUserAvatar()

  return (
    <div className="w-full">
      <div className="w-full border-b border-slate-200 text-black">
        <div className="mx-auto max-w-5xl py-12 px-6 ">
          <h1 className="m-0 text-center font-sans text-4xl font-semibold leading-10 tracking-tighter">
            <RenderChildText properties={titleProperties} />
          </h1>
          <div className="py-5 text-center text-sm text-gray-600">
            {date && formatNotionDate(date, 'MMM DD, YYYY')}
          </div>

          <div className="flex items-center justify-center">
            <div className="flex-shrink-0">
              <a href="/">
                <span className="sr-only">{userName}</span>
                <img
                  className="h-7 w-7 rounded-full"
                  src={avatar}
                  alt="avatar"
                />
              </a>
            </div>
            <div className="ml-3">
              <p className="text-base font-medium text-gray-900">{userName}</p>
            </div>
          </div>
          <SharedNavigation
            navigation={config.navigation}
            className="text-black md:pt-12 pt-4 static"
          />
        </div>
      </div>

      <div className="mx-auto max-w-5xl px-6 py-10">
        <NotionContent recordMap={contentRecordMap} />
      </div>

      <TimelineBlogFooter />
    </div>
  )
}

export default TimelineLayout
