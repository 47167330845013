import useSiteOwnerName from '@/lib/hooks/useSiteOwnerName'
import dayjs from 'dayjs'

const TimelineBlogFooter = () => {
  const userName = useSiteOwnerName()

  return (
    <div className="justify-between border-t border-slate-200 bg-zinc-50 px-6 py-10 text-slate-500 dark:border-slate-200/5 sm:flex">
      <div className="mx-auto mb-6 max-w-5xl sm:mb-0 sm:flex">
        <p>Copyright &copy; {`${dayjs().year()} ${userName}`}</p>
      </div>
    </div>
  )
}

export default TimelineBlogFooter
