import dynamic from 'next/dynamic'
import { NotionComponents } from 'react-notion-x'
import { NotionLinkToNextLink } from './notionLinkToNextLink'

const Code = dynamic(() =>
  import('react-notion-x/build/third-party/code').then((m) => m.Code)
)
// const Collection = dynamic(() =>
//   import('react-notion-x/build/third-party/collection').then(
//     (m) => m.Collection
//   )
// )
const Equation = dynamic(() =>
  import('react-notion-x/build/third-party/equation').then((m) => m.Equation)
)
// const Pdf = dynamic(
//   () => import('react-notion-x/build/third-party/pdf').then((m) => m.Pdf),
//   {
//     ssr: false,
//   }
// )
const Modal = dynamic(
  () => import('react-notion-x/build/third-party/modal').then((m) => m.Modal),
  {
    ssr: false,
  }
)

export const NotionRenderComponentsOverride: Partial<NotionComponents> = {
  PageLink: NotionLinkToNextLink,
  Code,
  Collection: () => null, // do not render
  Equation,
  Modal,
  Pdf: () => null,
}
