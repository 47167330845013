'use client'
import GlobalContext from '@/components/globalContext'
import ServerContext from '@/components/serverContext'
import { useContext } from 'react'

export const useBlockMap = () => {
  const { blockMap } = useContext(ServerContext)
  return blockMap
}

export const useCollectionViewMap = () => {
  const { collectionViewMap } = useContext(ServerContext)
  return collectionViewMap
}

export const useUser = () => {
  const { user } = useContext(GlobalContext)
  return user
}

export const useUserConfig = () => {
  const { config } = useContext(GlobalContext)
  return config
}

export const useIsSubscribed = () => {
  const { isSubscribed } = useContext(GlobalContext)
  return isSubscribed
}
