import { cn } from '@/lib/utils'
import React from 'react'
import Footer from './footer'

// {/* TODO plugins */}
// {/* <GlobalPlugins config={config} /> */}
//       {/* TODO footer */}
// {/* <Footer socialConfig={config.plugins?.social} /> */}
interface LayoutProps {
  header: JSX.Element
  content: JSX.Element
}
const Layout: React.FC<LayoutProps> = ({ header, content }) => {
  return (
    <div
      className={cn(
        'mx-auto flex h-full min-h-screen w-full max-w-3xl flex-col justify-between px-5 py-10 sm:px-6 md:px-0 xl:px-12'
      )}
    >
      <div>
        {header}
        {content}
      </div>
      <Footer />
    </div>
  )
}

export default Layout
