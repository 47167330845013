import { cn } from '@/lib/utils'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import React from 'react'

interface ExtraProps {
  children: any
  activeClassName: string
}
type LinkWrapperProps = React.PropsWithChildren<
  Omit<React.ComponentProps<typeof Link>, keyof ExtraProps> & ExtraProps
>
const ActiveLink = ({ activeClassName, ...props }: LinkWrapperProps) => {
  const pathName = usePathname()
  const isActive = pathName === props.href
  return (
    <Link
      className={cn(props.className, isActive && activeClassName)}
      {...props}
    />
  )
}
export default ActiveLink
