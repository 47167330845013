import dayjs from 'dayjs'
import { getDateValue } from 'notion-utils'
import { NotionCollectionDate } from '../types'

export const getDayJSDateFromProperty = (date: NotionCollectionDate) => {
  const dateValue = getDateValue(date)
  if (!dateValue || !dateValue?.start_date) {
    return dayjs()
  }
  return dayjs(dateValue.start_date, 'YYYY-MM-DD')
}
