'use client'
import { NotionPageCover } from '@/components/notion-related/notionPageCover'
import NotionContent from '@/components/notionContent'
import useSiteUserAvatar from '@/lib/hooks/useSiteUserAvatar'
import { BlogDetailPageProps } from '@/lib/types'
import formatNotionDate from '@/lib/utils/formatNotionDate'
import { getPageTitle } from 'notion-utils'
import Header from './components/header'
import Layout from './components/layout'

const SimpleBlogDetailPage = ({
  recordMap,
  siteInfo,
  blogInfo,
  isFromNavigation,
}: BlogDetailPageProps) => {
  const avatar = useSiteUserAvatar()

  return (
    <Layout
      header={
        <Header
          avatar={avatar}
          titleProperties={siteInfo.title}
          isFromNavigation={isFromNavigation}
        />
      }
      content={
        <>
          <NotionPageCover recordMap={recordMap} />
          <h1 className="mb-6 text-4xl font-extrabold tracking-tight text-slate-900 dark:text-slate-200 sm:text-4xl">
            {getPageTitle(recordMap)}
          </h1>
          {blogInfo && (
            <div className="mr-3 font-mono text-xs xl:mb-10">
              {formatNotionDate(blogInfo.date, 'MMM DD, YYYY') ||
                'Date Unknown'}
            </div>
          )}

          <NotionContent recordMap={recordMap} />
        </>
      }
    />
  )
}

export default SimpleBlogDetailPage
