'use client'
import { NotionRenderComponentsOverride } from '@/components/notionRenderComponentsOverride'
import { NotionRenderer } from 'react-notion-x'

const NotionContent: typeof NotionRenderer = (props) => (
  <div className="custom-notion">
    <NotionRenderer
      components={NotionRenderComponentsOverride}
      fullPage={false}
      darkMode={false}
      className="prose prose-stone max-w-full flex-auto"
      {...props}
    />
  </div>
)
export default NotionContent
