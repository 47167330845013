/* eslint-disable @next/next/no-img-element */
import SharedFooter from '@/components/blogTemplates/sharedFooter'
import { SharedNavigation } from '@/components/blogTemplates/sharedNavigation'
import RenderChildText from '@/components/notion-related/renderChildText'
import { SiteNavigation } from '@/lib/types'
import { Decoration } from 'notion-types'
import { ReactNode } from 'react'

const WindLayout: React.FC<{
  bannerImage: string
  title: Decoration[]
  description: Decoration[] | undefined
  navigation: SiteNavigation[]
  children?: ReactNode
}> = (props) => {
  const { bannerImage, title, description, children } = props
  // const userName = useSiteOwnerName()
  // const avatar = useSiteUserAvatar()

  return (
    <div className="flex h-full min-h-screen flex-col">
      <div className="relative">
        <div className="w-full">
          <div className="relative shadow-xl sm:overflow-hidden">
            <div className="absolute inset-0">
              {bannerImage && (
                <img
                  className="h-full w-full object-cover"
                  src={bannerImage}
                  alt="Cover Image"
                />
              )}
              <div className="absolute inset-0 bg-gradient-to-r from-gray-700 to-gray-600 mix-blend-multiply" />
            </div>

            <SharedNavigation
              navigation={props.navigation}
              className="pr-4 sm:pr-9"
            />

            <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
              <h1 className="text-center text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
                <RenderChildText properties={title} />
              </h1>
              {description && (
                <p className="mx-auto mt-6 max-w-lg text-center text-xl text-indigo-200 sm:max-w-3xl">
                  <RenderChildText properties={description} />
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="relative flex flex-1 flex-col bg-gray-50 px-4 pt-8 sm:px-6 sm:pt-16 lg:px-8 lg:pt-24">
        <div className="relative mx-auto flex w-full max-w-7xl flex-col justify-between">
          {children}

          <SharedFooter />
        </div>
      </div>
    </div>
  )
}

export default WindLayout
