import { getDayJSDateFromProperty } from './getDateFromProperty'

// user may not define date
const formatNotionDate = (date: any | undefined, template: string) => {
  if (!date) {
    return ''
  }
  return getDayJSDateFromProperty(date).format(template)
}
export default formatNotionDate
