import { PageValueType } from '@/components/notion-related/types'
import { BlogInfo } from '@/lib/types'
import { getNotionFileValue } from '@/lib/utils/getNotionFileValue'
import { ExtendedRecordMap } from 'notion-types'

export const buildImageUrl = (
  cover: string | undefined,
  blockId: string,
  type: 'block' | 'collection' = 'block'
) => {
  if (!cover) {
    return ''
  }
  if (
    cover?.includes('secure.notion-static.com') ||
    cover?.includes('https://prod-files-secure')
  ) {
    return `https://www.notion.so/image/${encodeURIComponent(
      cover
    )}?table=${type}&id=${blockId}`
  } else if (cover?.startsWith('/')) {
    return `https://www.notion.so${cover}`
  } else {
    return cover
  }
}

export const buildBlogIndexPageSingleBlockCoverUrl = (
  cover: BlogInfo['cover'],
  blockId: string
) => {
  if (typeof cover === 'string') {
    return cover
  }
  return buildImageUrl(getNotionFileValue(cover, ''), blockId)
}

export const buildImageUrlWithRecordMap = (
  blockMap: ExtendedRecordMap['block'],
  type: 'block' | 'collection' = 'block'
) => {
  const pageBlock = blockMap[Object.keys(blockMap)[0]].value as PageValueType
  const page_cover = pageBlock.format?.page_cover

  return buildImageUrl(page_cover, pageBlock.id, type)
}
