'use client'
/* eslint-disable @next/next/no-img-element */
import ActiveLink from '@/components/home/activeLink'
import { useIsSubscribed } from '@/lib/hooks/context'
import { SiteNavigation } from '@/lib/types'
import { cn } from '@/lib/utils'
import { isInSiteLink } from '@/lib/utils/isInSiteLink'
import Link from 'next/link'

export const SharedNavigation = (props: {
  navigation: SiteNavigation[]
  className?: string
}) => {
  const isSubscribed = useIsSubscribed()

  return (
    <nav
      className={cn(
        'absolute right-0 top-0 z-50 flex justify-center text-white',
        props.className
      )}
    >
      <div className="mt-4 flex gap-3">
        {isSubscribed &&
          props.navigation.map((item, index) => {
            const href =
              item.type === 'home' ? '/' : `/${item.label}`.toLowerCase()
            return (
              <div key={index} className="cursor-pointer ">
                {isInSiteLink(item.type) ? (
                  <ActiveLink href={href} passHref activeClassName="font-bold">
                    <div>{item.label}</div>
                  </ActiveLink>
                ) : (
                  <a
                    target="_blank"
                    href={item.value}
                    rel="noopener noreferrer"
                  >
                    {item.label}
                  </a>
                )}
              </div>
            )
          })}
        {isSubscribed && (
          <Link href="/rss.xml" passHref>
            <div className="cursor-pointer ">RSS</div>
          </Link>
        )}
      </div>
    </nav>
  )
}
