'use client'
/* eslint-disable @next/next/no-img-element */
import WindLayout from '@/components/blogTemplates/wind/components/WindLayout'
import { useUserConfig } from '@/lib/hooks/context'
import useSiteOwnerName from '@/lib/hooks/useSiteOwnerName'
import useSiteUserAvatar from '@/lib/hooks/useSiteUserAvatar'
import {
  buildBlogIndexPageSingleBlockCoverUrl,
  buildImageUrl,
} from '@/lib/notion-api/utils'
import { SiteInfo, TemplateUserConfigResp } from '@/lib/types'
import formatNotionDate from '@/lib/utils/formatNotionDate'
import getBlogPath from '@/lib/utils/getBlogPath'
import Link from 'next/link'
import { getTextContent } from 'notion-utils'

const WindBlogIndexPage: React.FC<{
  resp: SiteInfo
  config: TemplateUserConfigResp
}> = ({ resp }) => {
  const config = useUserConfig()
  const userName = useSiteOwnerName()
  const avatar = useSiteUserAvatar()

  return (
    <WindLayout
      bannerImage={
        resp.coverImageUrl
          ? buildImageUrl(
              resp.coverImageUrl,
              resp.notionCollectionPageId,
              'collection'
            )
          : ''
      }
      title={resp.title}
      description={resp.description}
      navigation={config.navigation}
    >
      <div className="mx-auto grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
        {resp.items.map((article, idx) => {
          const imageUrl = buildBlogIndexPageSingleBlockCoverUrl(
            article.properties.cover,
            article.id
          )
          return (
            <div
              key={idx}
              className="flex flex-col overflow-hidden rounded-lg shadow-lg"
            >
              <Link
                href={getBlogPath(article.properties.slug)}
                as={getBlogPath(article.properties.slug)}
                passHref
              >
                <div className="flex-shrink-0">
                  {imageUrl ? (
                    <img
                      className="h-48 w-full object-cover"
                      src={imageUrl}
                      alt=""
                    />
                  ) : (
                    <div className="h-48 w-full bg-gray-400" />
                  )}
                </div>
              </Link>
              <div className="flex flex-1 flex-col justify-between bg-white p-6">
                <div className="flex-1">
                  <p className="text-sm font-medium text-indigo-600">
                    {article.properties.tag?.map((v, idx) => (
                      <span key={idx} className="hover:underline">
                        {v}
                      </span>
                    ))}
                  </p>
                  <Link
                    href={getBlogPath(article.properties.slug)}
                    as={getBlogPath(article.properties.slug)}
                    passHref
                  >
                    <div className="mt-2 block">
                      <p className="text-xl font-semibold text-gray-900">
                        {getTextContent(article.properties.page)}
                      </p>
                      <p className="mt-3 text-base text-gray-500">
                        {getTextContent(article.properties.description)}
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <a href="/">
                      <span className="sr-only">{userName}</span>
                      <img
                        className="h-10 w-10 rounded-full object-cover"
                        src={avatar}
                        alt="avatar"
                      />
                    </a>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">
                      {userName}
                    </p>
                    <div className="flex space-x-1 text-sm text-gray-500">
                      {formatNotionDate(
                        article.properties.date,
                        'MMM DD, YYYY'
                      )}
                      {/* <span aria-hidden="true">&middot;</span> */}
                      {/* <span>{article.readingTime} read</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </WindLayout>
  )
}

export default WindBlogIndexPage
