import { buildImageUrlWithRecordMap } from '@/lib/notion-api/utils'
import { cn } from '@/lib/utils'
import { ExtendedRecordMap } from 'notion-types'

const getBackgroundImageStyle = (coverUrl: string, dark: boolean) => {
  return [
    dark && 'linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))',
    coverUrl ? `url('${coverUrl}')` : '',
  ]
    .filter(Boolean)
    .join(', ')
}

export const NotionPageCover = (props: { recordMap: ExtendedRecordMap }) => {
  const imgUrl = buildImageUrlWithRecordMap(props.recordMap.block)
  if (!imgUrl) {
    return null
  }
  const backgroundImage = getBackgroundImageStyle(
    buildImageUrlWithRecordMap(props.recordMap.block),
    true
  )
  return (
    <div
      className={cn(
        'relative mb-8 flex h-64 w-full items-center justify-center overflow-hidden rounded-lg',
        'bg-cover bg-center bg-no-repeat'
      )}
      style={{ backgroundImage }}
    />
  )
}
