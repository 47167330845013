import useSiteOwnerName from '@/lib/hooks/useSiteOwnerName'
import dayjs from 'dayjs'

const Footer = () => {
  const userName = useSiteOwnerName()

  return (
    <div className="mt-16 justify-between border-t border-slate-200 pt-10 text-slate-500 dark:border-slate-200/5 sm:flex">
      <div className="mb-6 sm:mb-0 sm:flex">
        <p>Copyright &copy; {`${dayjs().year()} ${userName}`}</p>
      </div>
    </div>
  )
}
export default Footer
