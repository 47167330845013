import(/* webpackMode: "eager" */ "/vercel/path0/components/blogTemplates/sharedFooter.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/blogTemplates/sharedNavigation.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/blogTemplates/simple/simpleBlogDetailPage.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/blogTemplates/simple/simpleBlogIndexPage.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/blogTemplates/timeline/components/timelineLayout.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/blogTemplates/timeline/timelineBlogIndexPage.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/blogTemplates/wind/windBlogDetailPage.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/blogTemplates/wind/windBlogIndexPage.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/notion-related/renderChildText.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/notionContent.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/lib/hooks/context.ts");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@13.5.6_@babel+core@7.23.9_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@13.5.6_@babel+core@7.23.9_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js")