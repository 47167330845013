'use client'
import { TemplateUserConfigResp, UIUser } from '@/lib/types'
import React from 'react'

const GlobalContext = React.createContext<{
  user: UIUser
  config: TemplateUserConfigResp
  isSubscribed: boolean
}>({ user: null, config: null, isSubscribed: false } as any)

export default GlobalContext
