'use client'
import { PageValueType } from '@/components/notion-related/types'
import { useBlockMap } from '@/lib/hooks/context'
import Link from 'next/link'
import { Decoration } from 'notion-types'
import * as React from 'react'
// import { InlineMath } from 'react-katex'

const RenderChildText = ({ properties }: { properties: Decoration[] }) => {
  const blockMap = useBlockMap()
  return (
    <>
      {properties?.map(([text, decorations], i) => {
        if (!decorations) {
          return <React.Fragment key={i}>{text}</React.Fragment>
        }

        return (
          <React.Fragment key={i}>
            {decorations.reduceRight((element, decorator) => {
              switch (decorator[0]) {
                case 'h':
                  return (
                    <span className={`notion-${decorator[1]}`}>{element}</span>
                  )
                case 'c':
                  return <code className="notion-inline-code">{element}</code>
                case 'b':
                  return <span className="font-semibold">{element}</span>
                case 'i':
                  return <span className="italic">{element}</span>
                case 's':
                  return <span className="line-through">{element}</span>
                case 'a':
                  return (
                    <a
                      className="notion-link"
                      href={decorator[1]}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {element}
                    </a>
                  )
                case 'e':
                  // TODO: new this is commented
                  // return <InlineMath key={i}>{decorator[1]}</InlineMath>
                  return null
                case 'p': // link to page
                  return (
                    <Link href="/[pageId]" as={`/${decorator[1]}`}>
                      <div className="notion-link">
                        <RenderChildText
                          properties={
                            (blockMap[decorator[1]]?.value as PageValueType)
                              ?.properties?.title || []
                          }
                        />
                      </div>
                    </Link>
                  )
                // console.log(decorator)
                // console.log(blockMap[decorator[1]])
                default:
                  return element
              }
            }, text as React.ReactNode)}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default RenderChildText
